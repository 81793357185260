<template>
    <div class="row full-height justify-center items-center q-pt-lg">
        <div class="col-md-4 col-sm-6 col-xs-10">
            <q-card>
                <q-card-section>
                    <q-form action="" method="" @submit.prevent="onSubmit">
                        <BaseInput label="Логин" v-model="form.username" />
                        <BaseInput
                            type="password"
                            label="Пароль"
                            v-model="form.password"
                        />
                        <Transition name="slide-up">
                            <div v-if="isError" class="q-mt-md text-negative">
                                Неверный логин или пароль
                            </div>
                        </Transition>
                        <BaseButton
                            label="Войти"
                            type="submit"
                            color="primary"
                            class="q-mt-md"
                        />
                    </q-form>
                </q-card-section>
            </q-card>
        </div>
    </div>
</template>

<script>
import { ref, watch } from 'vue'
import { useUserStore } from '@/stores/user'
import { useRoute, useRouter } from 'vue-router'

export default {
    name: 'Login',
    setup() {
        const router = useRouter()
        const route = useRoute()
        const { loginUser } = useUserStore()

        const isError = ref(false)

        const form = ref({
            username: '',
            password: '',
        })

        watch(
            form,
            () => {
                if (!isError.value) return

                isError.value = false
            },
            { deep: true },
        )

        const onSubmit = async () => {
            try {
                await loginUser(form.value)

                if (route.query.redirectTo) {
                    router.push(route.query.redirectTo)
                    return
                }

                router.push({
                    name: 'Shops',
                })
            } catch (error) {
                isError.value = true
            }
        }

        return {
            form,
            onSubmit,
            isError,
        }
    },
}
</script>
